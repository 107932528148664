import React, {useEffect, useState} from 'react';
import Title from "../../../../components/title";
import {OverlayLoader} from "../../../../components/loader";
import Checkbox from "../../../../components/checkbox";
import {filter as filterArray, get, includes, isArray, isEmpty, isEqual, sumBy, uniq} from "lodash";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import {BarChart} from "../../../../components/chart";
import {calculateDashboardChartData, calculateDashboardChartDataPercent} from "../../../../utils";
import Section from "../../../../components/section";
import {useTranslation} from "react-i18next";
import {useGetAllQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/keys";
import {URLS} from "../../../../constants/urls";
import ChartDatepicker from "../chart-datepicker";
import {NumericFormat} from "react-number-format";

const Index = ({regionList = {}, category= 'P1', title = ''}) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [regions, setRegions] = useState([])
    const [searchParams] = useSearchParams();
    const [month, setMonth] = useState(searchParams.get('month') ?? dayjs().subtract(1, 'month').format('YYYYMM'))


    const {data: reports, isLoading: isLoadingReports} = useGetAllQuery({
        key: [KEYS.dashboardReports,month],
        url: `${URLS.dashboardReports}/${month}/states`,
        params: {params: {state_ids: regions}},
        enabled: !!month
    })

    useEffect(() => {
        if (month) {
            navigate(`/?tab=${searchParams.get('tab')}&month=${month}`)
        }
    }, [month])

    useEffect(() => {
        if (get(regionList, 'data.data') && isArray(get(regionList, 'data.data'))) {
            setRegions(get(regionList, 'data.data', []).map(({id}) => id))
        }
    }, [regionList]);

    return (
        <>
            <Section>
                <Title
                    className={'mb-4 !text-2xl'}>{t(title)}</Title>
                <div className="grid grid-cols-12">
                    <div className={'col-span-2'}>
                        {<>
                            <h3 className={'font-bold text-sm'}>{t("Region")}</h3>
                            <ul className={'py-2.5'}>
                                <li className={'mb-2 flex items-center'}><Checkbox
                                    checked={!isEmpty(regions)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setRegions(get(regionList, 'data.data', []).map(({id}) => id))
                                        } else {
                                            setRegions([])
                                        }
                                    }}
                                />
                                    <span>{t('All')}</span>
                                </li>
                                {
                                    isArray(get(regionList, 'data.data', [])) && get(regionList, 'data.data', []).map((region) =>
                                        <li key={get(region, 'id')} className={'mb-2 flex items-center'}>
                                            <Checkbox
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setRegions(uniq([...regions, get(region, 'id')]))
                                                    } else {
                                                        setRegions(uniq(filterArray(regions, (_region) => !isEqual(_region, get(region, 'id')))))
                                                    }
                                                }}
                                                checked={includes(regions, get(region, 'id'))}
                                            />
                                            <Link to={{
                                                pathname: '/',
                                                search: `?tab=${searchParams.get('tab')}&regionId=${get(region, 'id')}&month=${month}`,
                                            }}>{get(region, 'name')}</Link>
                                        </li>)
                                }
                            </ul>
                        </>}
                    </div>
                    <div className="col-span-10 h-[675px]">
                        {(isLoadingReports) && <OverlayLoader/>}
                        <div className="flex justify-end">
                            <ul className="list-none flex-none mr-5">
                                <li><strong>{t('Жен')}.</strong><span
                                    className={'ml-1.5'}>{sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    {
                                        category === 'P2' ?
                                          <>
                                              {" | "}
                                              <NumericFormat displayType={'text'}
                                                             value={
                                                                 ((sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female') * 100) /
                                                               (sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') + sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female'))).toFixed(1)
                                                             }/>%
                                          </>
                                         : ''
                                    }
                                </span>
                                </li>
                                <li><strong>{t('Муж')}.</strong>
                                     <span className={'ml-1.5'}>{sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        {
                                            category === 'P2' ?
                                              <>
                                                  {" | "}
                                                  <NumericFormat displayType={'text'}
                                                                 value={
                                                                     ((sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') * 100) /
                                                                       (sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') + sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female'))).toFixed(1)
                                                                 }/>%
                                              </>
                                              : ''
                                        }
                                    </span>
                                </li>
                                <li><strong>{t('Grand Total')}:</strong>
                                    <span className={'ml-1.5'}>{(sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') + sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        {
                                            category === 'P2' ? <>
                                                {" | "}
                                                <NumericFormat displayType={'text'}
                                                               value={
                                                                   ((sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') + sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female')) * 100 ) /
                                                                 (sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'male') + sumBy(calculateDashboardChartData(get(reports, 'data.data', []), regions), 'female'))
                                                               }/>%
                                            </> : ''
                                        }
                                    </span>
                                </li>
                            </ul>
                            <ChartDatepicker month={month} setMonth={setMonth}/>
                        </div>
                        <BarChart
                            data={category === 'P3' ? calculateDashboardChartDataPercent(get(reports, 'data.data', []), regions) :
                              calculateDashboardChartData(get(reports, 'data.data', []), regions)}
                            category={category}
                        />
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Index;

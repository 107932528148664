import React, {useEffect, useState} from 'react';
import {useGetAllQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/keys";
import {URLS} from "../../../../constants/urls";
import {filter as filterArray, get, head, includes, isArray, isEmpty, isEqual, uniq, find, sumBy} from "lodash";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import Title from "../../../../components/title";
import {OverlayLoader} from "../../../../components/loader";
import Checkbox from "../../../../components/checkbox";
import ChartDatepicker from "../chart-datepicker";
import {BarChart} from "../../../../components/chart";
import {calculateDashboardChartData, calculateDashboardChartDataPercent} from "../../../../utils";
import Section from "../../../../components/section";
import {useTranslation} from "react-i18next";
import Select from "../../../../components/select";
import prevIcon from "../../../../assets/icons/prev.svg";
import {NumericFormat} from "react-number-format";


const Index = ({regionList = {}, title = ''}) => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams();
    const [organization, setOrganization] = useState([])
    const [month, setMonth] = useState(searchParams.get('month') ?? dayjs().subtract(1, 'month').format('YYYYMM'))
    const [regionId, setRegionId] = useState(null);
    const navigate = useNavigate()

    const {data: organizationList, isLoading: isLoadingOrganizationList} = useGetAllQuery({
        key: KEYS.cities,
        url: `${URLS.dashboardReports}/${month}/states/${get(regionId, 'value')}/organizations`,
        params: {
            params: {
                per_page: 1000
            }
        },
        enabled: !!get(regionId, 'value')
    })

    const {data: organizationReports, isLoading: isLoadingOrganizationReports} = useGetAllQuery({
        key: KEYS.dashboardReports,
        url: `${URLS.dashboardReports}/${month}/states/${get(regionId, 'value')}/organizations`,
        params: {params: {organization_ids: organization}},
        enabled: !!get(regionId, 'value')
    })


    useEffect(() => {
        if (get(organizationList, 'data.data') && isArray(get(organizationList, 'data.data'))) {
            setOrganization(get(organizationList, 'data.data', []).map(({id}) => id))
        }
    }, [organizationList]);

    useEffect(() => {
        if (!searchParams.get('regionId') && get(regionList, 'data.data') && isArray(get(regionList, 'data.data'))) {
            setRegionId({
                value: get(head(get(regionList, 'data.data', [])), 'id'),
                label: get(head(get(regionList, 'data.data', [])), 'name')
            })
        }
    }, [regionList]);

    useEffect(() => {
        if (!isEmpty(get(regionList, 'data.data', [])) && searchParams.get('regionId')) {
            setRegionId({
                value: get(find(get(regionList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('regionId')), 'id'),
                label: get(find(get(regionList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('regionId')), 'name')
            })
        }
    }, [searchParams.get('regionId'), get(regionList, 'data.data', [])]);


    return (
        <>
            <Section>
                <Title
                  className={'mb-4 !text-2xl flex flex-col'}>
                    <button
                      onClick={() => navigate(-1)}
                      className={'w-fit inline-flex items-center py-2.5 pl-2.5 pr-5 text-sm font-bold text-primary border border-primary rounded-lg mb-2'}>
                        <img className={'mr-2.5'} src={prevIcon} alt={'prev'}/>
                        {t('Вернуться назад')}
                    </button>
                    {t(title)}
                </Title>
                <div className="grid grid-cols-12">
                    <div className={'col-span-2'}>
                        {isLoadingOrganizationList && <OverlayLoader/>}
                        <Select
                          isClearable={false}
                          value={regionId}
                          setValue={(val) => setRegionId(val)}
                            label={t('Region')} options={get(regionList, 'data.data', []).map(item => ({
                            value: get(item, 'id'),
                            label: get(item, 'name')
                        }))}/>
                        <h3 className={'mt-3 font-bold py-1'}>{t("Organizations")}</h3>
                        <ul className={'py-2.5 max-h-[60vh] overflow-y-auto pr-1'}>
                            <li className={'mb-2 flex items-center'}>
                                <Checkbox
                                    checked={!isEmpty(organization)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setOrganization(get(organizationList, 'data.data', []).map(({id}) => id))
                                        } else {
                                            setOrganization([])
                                        }
                                    }}
                                />
                                <span>{t('All')}</span>
                            </li>
                            {
                                isArray(get(organizationList, 'data.data', [])) && get(organizationList, 'data.data', []).map((_city) =>
                                    <li key={get(_city, 'id')} className={'mb-2 flex items-center'}><Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setOrganization(uniq([...organization, get(_city, 'id')]))
                                            } else {
                                                setOrganization(uniq(filterArray(organization, (__city) => !isEqual(__city, get(_city, 'id')))))
                                            }
                                        }}
                                        checked={includes(organization, get(_city, 'id'))}
                                    />
                                        <Link to={{
                                            pathname: '/',
                                            search: `?tab=${searchParams.get('tab')}&regionId=${get(regionId, 'value')}&organizationId=${get(_city, 'id')}&month=${month}`,
                                        }}>{get(_city, 'name')}</Link>
                                    </li>)
                            }
                        </ul>
                    </div>
                    <div className="col-span-10 h-[675px]">
                        <div className="flex justify-end">
                            <ul className="list-none flex-none mr-5">
                                <li><strong>{t('Жен')}.</strong><span
                                    className={'ml-1.5'}>{sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    {
                                        searchParams.get('tab') === 'P2' ?
                                          <>
                                              {" | "}
                                              <NumericFormat displayType={'text'}
                                                             value={
                                                                 ((sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female') * 100) /
                                                                   (sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') + sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female'))).toFixed(1)
                                                             }/>%
                                          </>
                                          : ''
                                    }
                                </span>
                                </li>
                                <li><strong>{t('Муж')}.</strong>
                                    <span className={'ml-1.5'}>{sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        {
                                            searchParams.get('tab') === 'P2' ?
                                              <>
                                                  {" | "}
                                                  <NumericFormat displayType={'text'}
                                                                 value={
                                                                     ((sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') * 100) /
                                                                       (sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') + sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female'))).toFixed(1)
                                                                 }/>%
                                              </>
                                              : ''
                                        }
                                    </span>
                                </li>
                                <li><strong>{t('Grand Total')}:</strong>
                                    <span className={'ml-1.5'}>{(sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') + sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        {
                                            searchParams.get('tab') === 'P2' ? <>
                                                {" | "}
                                                <NumericFormat displayType={'text'}
                                                               value={
                                                                 ((sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') + sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female')) * 100 ) /
                                                                 (sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'male') + sumBy(calculateDashboardChartData(get(organizationReports, 'data.data', []), organization), 'female'))
                                                               }/>%
                                            </> : ''
                                        }
                                    </span>
                                </li>
                            </ul>
                            <ChartDatepicker month={month} setMonth={setMonth}/>
                        </div>
                        <BarChart
                            data={searchParams.get('tab') === 'P3' ? calculateDashboardChartDataPercent(get(organizationReports, 'data.data', []), organization) : calculateDashboardChartData(get(organizationReports, 'data.data', []), organization)}
                            category={searchParams.get('tab')}
                        />
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Index;

export const URLS = {
    oauth: '/oauth/authorize',
    token: '/oauth/token',
    getMe: '/me',
    login: '/auth/login',
    outpatientReports: '/reports/outpatient',
    inpatientReports: '/reports/inpatient',
    baseRates: '/base-rates',
    inpatientBaseRates: '/inpatient-base-rates',
    patientGroups: '/patient-groups',
    drgs: '/drgs',
    organizationLevel: '/organizations/levels',
    organizationType: '/organizations/types',
    locales: '/site/locales',
    translations: '/site/translations',
    translationsKey: '/site/translations/key',
    reports: '/reports',
    drg_report: '/reports/drg_report',
    form_66_report: '/reports/statistical_form_066_report',
    states: '/states',
    cities: '/cities',
    dashboardReports: '/dashboard/reports/outpatient',
    outpatient_by_state_and_month: '/reports/outpatient_by_state_and_month',
    practitioner: '/reports/practitioner',
    months: '/reports/practitioner/months',
    allInpatientReports: '/reports/inpatient/forms066/list',
    icd10:'/rb/icd10',
    organizations:'/organizations',
    notifications: '/notifications'
}

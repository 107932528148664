import React from 'react';
import CustomDatepicker from "../../../../components/datepicker";
import dayjs from "dayjs";
import {get} from "lodash";
import {useGetAllQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/keys";
import {URLS} from "../../../../constants/urls";

const Index = ({setMonth = () => {},month}) => {
    const {data: dateList} = useGetAllQuery({
        key: KEYS.outpatientReports,
        url: URLS.outpatientReports,
        params: {
            params: {
                per_page: 1000
            }
        }
    })
    return (
        <>
            <div className="flex justify-end w-48  mb-0 relative">
                <CustomDatepicker
                    defaultValue={dayjs(month).toDate()}
                    handleDate={(val) => setMonth(dayjs(val).format('YYYYMM'))}
                    includeDates={get(dateList, 'data.data', []).map(({month}) => month) || []}
                    property={{showMonthYearPicker: true}} dateFormat="MM/yyyy"/>
            </div>
        </>
    );
};

export default Index;
import React from 'react';
import DashboardContainer from "../containers/DashboardContainer";

const DashboardPage = () => {
    return (
        <>
         <DashboardContainer />
        </>
    );
};

export default DashboardPage;
import React, {useEffect, memo, useState} from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {TreePicker} from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import arrowIcon from "../../assets/icons/select-arrow.svg"
import useTreeData from "../../hooks/general/use-tree-data";
import {debounce, get, includes} from "lodash"
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

const TreePickerSelect = ({
                              label = '',
                              classNames = '',
                              url = '',
                              keyId = 'list',
                              isDirty = false,
                              value,
                              setValue = () => {
                              }
                          }) => {
    const {isLoading, getNodes, fetchNodes, searchNodes, setLoading, searchLoading} = useTreeData({keyId, url})
    const {t} = useTranslation()
    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [loadingValues, setLoadingValues] = useState([])
    const [_activeNode, _setActiveNode] = useState(null)
    const getChildren = (activeNode) => {
        _setActiveNode(activeNode);
        return fetchNodes(activeNode).then((response) => {
            return {
                activeNode: _activeNode,
                children: getNodes(response)
            }
        })
    }
    const handleChange = (val) => {
        setValue(val)
    }
    const handleOpen = () => {
        if (data.length === 0) {
            setData(getNodes())
        }
    }
    const handleOnExpand = (activeNode, layer, concat) => {
        if (layer.children?.length === 0) {
            if (!loadingValues.includes(layer.value)) {
                setLoadingValues([...loadingValues, layer.value]);
            }

            getChildren(layer).then(response => {
                const {activeNode: node, children} = response;
                setData(prev => concat(data, children))
                setLoadingValues(prev => prev.filter(
                    value => value !== layer?.value
                ))
            });
        }
    }

    const handleSearch = debounce((val) => {
        setSearchValue(val);
        setLoadingValues([])
        searchNodes(val).then((response) => {
            setLoading(false);
            setData(getNodes(response))
        })
    }, 1500)

    const renderTreeIcon = (node) => {
        if (includes(loadingValues, node?.value)) {
            return <SpinnerIcon pulse color={'#000'}/>;
        }
        return null;
    }

    useEffect(() => {
        setData(getNodes())
    }, [])

    return (
        <div className={clsx(`form-group ${classNames}`)}>
            {label && <label className={clsx('form-label')}
                             htmlFor={label}>{label}</label>}

            <TreePicker
                searchable={true}
                loading={isLoading}
                value={value}
                size={"lg"}
                caretAs={() => <img src={arrowIcon} alt={'arrow'}/>}
                data={data}
                className={'w-full z-1'}
                placeholder={t("Select")}
                onOpen={handleOpen}
                onExpand={handleOnExpand}
                onChange={handleChange}
                // onSearch={handleSearch}
                renderTreeIcon={renderTreeIcon}
                renderValue={(value, item, selectedElement) => {
                    return (
                        <span>
                        {get(item, 'label')}
                         </span>
                    );
                }}
                renderTreeNode={nodeData => {
                    return (
                        <button className={'text-left p-1.5 !block !w-full'}>
                            {get(nodeData, 'label')}
                        </button>
                    );
                }}
                renderTree={(tree) => {
                    if (searchLoading) {
                        return <div className={'rs-search-box z-[9900099] relative'}>
                            <input
                                role="searchbox"
                                className="rs-search-box-input rs-input"
                                type="text"
                                placeholder="Search"
                                disabled
                                defaultValue={searchValue}
                            />
                            <p className={'p-1.5'}>{t("Loading ...")}</p>
                        </div>
                    }
                    return tree;
                }}
            />

            {isDirty &&
            <span className={'form-error'}>{t('Заполните обязательное поле')}</span>}
        </div>
    );
};

export default memo(TreePickerSelect);

import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList
} from 'recharts';
import {get, isArray, sumBy} from "lodash";
import maleIcon from "../../assets/icons/male.svg"
import femaleIcon from "../../assets/icons/female.svg"
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";

const CustomTooltip = ({active, payload, label, category}) => {
  const {t} = useTranslation()
  if (active && payload && payload.length) {
    return (
      category === 'P3' ? <div className="bg-white p-2.5 shadow-md rounded">
          <p><strong>{t('age_groups')}:</strong> {label}</p>
          <p><strong>%:</strong> { payload[0]?.value}%</p>
        </div> :
        <ul className="bg-white p-2.5">
          <li>{label}</li>
          {
            isArray(payload) && payload.map(_item => {
                return (
                  <li key={get(_item, 'name')}
                      className={`text-[${get(_item, 'fill')}]`}>{`${t(get(_item, 'name'))}: `}
                    <NumericFormat displayType={'text'} thousandSeparator={','} value={get(_item, 'value')}/>
                    {
                      category === 'P2' ? <>
                        {" | "}
                        <NumericFormat displayType={'text'}
                                       value={
                                         ((get(_item, 'value') * 100) / (sumBy(payload, 'value'))).toFixed(1)
                                       }/>%
                      </> : ''
                    }
                  </li>
                )
              }
            )
          }
          <li>{t("Total")}: <NumericFormat displayType={'text'} thousandSeparator={','}
                                           value={sumBy(payload, 'value')}/>
            {
              category === 'P2' ? <>
                {" | "}
                <NumericFormat displayType={'text'}
                               value={
                                 ((sumBy(payload, 'value') * 100) / (sumBy(payload, 'value'))).toFixed(1)
                               }/>%
              </> : ''
            }
          </li>

        </ul>
    );
  }

  return null;
};

const CustomBarChart = ({data = [], category = ''}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={800}
        data={data}
        margin={{
                    top: 40,
                    right: 30,
                    left: 20,
                    bottom: 50,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="code"/>
              {category === 'P3' ? <YAxis tickFormatter={(tick) => `${tick}%`}/> :
                <YAxis/>}
                <Legend iconType={"circle"} content={<div className={'flex justify-center'}>
                    <img width={40} className={'mr-5'} src={maleIcon} alt=""/>
                    <img width={40} src={femaleIcon} alt=""/>
                </div>}/>
                <Tooltip content={<CustomTooltip category={category} />}/>
        {
          category === 'P3' ? <Bar dataKey="total" fill="#7CB0F4">
            <LabelList
              fontSize={12}
              formatter={(val) => `${val}%`}
              dataKey="total"
              position="center"
              fill={'white'}
            />
          </Bar> : <>
            <Bar isAnimationActive={false} dataKey="male" stackId="a" fill="#7CB0F4">
              <LabelList
                fontSize={12}
                formatter={category === 'P3' ? (val) => `${val}%` : (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                dataKey="male"
                position="center"
                fill={'white'}
              />
            </Bar>
            <Bar isAnimationActive={false} dataKey="female" stackId="a" fill="#DF6C91">
              <LabelList
                fontSize={12}
                formatter={category === 'P3' ? (val) => `${val}%` :  (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                dataKey="female"
                position="center"
                fill={'white'}
              />

            </Bar>
          </>
        }

            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;

import React, {useMemo} from 'react';
import clsx from "clsx";
import {debounce} from "lodash"

const Input = ({
                  timeout=1000, label = '', value = '', setValue = () => {
    }, placeholder = '',classNames='', ...rest
               }) => {
    const handleChange = useMemo((e) => debounce((e) => {
        setValue(e.target.value)
    }, timeout), [])
    return (
        <>
            <div className={clsx("form-group mb-0",classNames)}>
                <label className={'form-label'} htmlFor="#">{label}</label>
                <input placeholder={placeholder} defaultValue={value} onChange={handleChange}  {...rest}
                       className={clsx('form-input')}
                       type={"search"}/>
            </div>
        </>
    );
};

export default Input;
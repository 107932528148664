import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import {isNil} from "lodash";

const Checkbox = ({
                      id,
                      label,
                      checked = null,
                      ...props
                  }) => {
    const [isChecked, setIsChecked] = useState(checked);
    useEffect(() => {
        if(!isNil(checked)){
            setIsChecked(checked)
        }
    }, [checked]);
    return (
        <div className="checkbox-wrapper cursor-pointer">
            <input
                id={id}
                type="checkbox"
                className={clsx('inline-block flex-none',{'checked': isChecked})}
                checked={isChecked}
                onChange={() => setIsChecked((prev) => !prev)}
                {...props}
            />
            <label className={'cursor-pointer'} htmlFor={id}>{label}</label>
        </div>
    );
};

export default Checkbox;
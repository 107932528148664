import {useState} from 'react';
import {useGetAllQuery} from "../api";
import {get} from "lodash";
import config from "../../config";
import {useSettingsStore} from "../../store";

const useTreeData = ({
                         keyId = "get-all",
                         url = "/",
                         limit = 100,
                     }) => {
    const [loading,setLoading] = useState(false);
    const token = useSettingsStore(state => get(state, 'token', null))
    const lang = useSettingsStore(state => get(state, 'lang', null))



    const {data: options, isLoading} = useGetAllQuery({
        key: [keyId], url: url, params: {
            params: {
                limit
            }
        },
    })


    const createNode = (item) => {
        const hasChildren = !get(item, 'is_last_level');
        const label = `${get(item, 'code')} ${get(item, 'display')}`;
        return {
            label,
            value: get(item, 'id'),
            children:hasChildren ? [] : null
        }
    }
    const getNodes = (items = get(options, 'data.data', [])) => {
        return items?.data ? items?.data?.map(_item => createNode(_item)) : items?.map(_item => createNode(_item))
    }
    const fetchNodes = async (activeNode) => {
         const res = await fetch(`${config.API_ROOT}${url}?parent_id=${get(activeNode,'value')}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': lang
            }
        });

         return res.json()
    };

    const searchNodes = async (str) => {
        setLoading(true);
        const res = await fetch(`${config.API_ROOT}${url}?q=${str}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return res.json()
    };

    return {
        isLoading,
        getNodes,
        fetchNodes,
        searchNodes,
        searchLoading:loading,
        setLoading
    }
};

export default useTreeData;

import {find, get, includes, filter, sumBy, isArray, isObject, values, isEmpty} from "lodash"

export const getDefaultValue = (options, id) => {
    return find(options, (option) => get(option, 'value') == id)
}

export const calculateDashboardChartData = (data = [], selected = [], _keys = ['male', 'female']) => {
    let inSelectedList = filter(data, (_item) => includes(selected, get(_item, 'id'))) || [];
    inSelectedList = inSelectedList.map(item => isObject(get(item, 'age_groups')) ? values(get(item, 'age_groups')) : get(item, 'age_groups')) || []
    let keys = isArray(find(inSelectedList,(_list)=>!isEmpty((_list)))) && find(inSelectedList,(_list)=>!isEmpty((_list))).map(_item => _item.code);
    let result = [];
    for (let i = 0; i < keys.length; i++) {
        result.push({
            code: keys[i],
            male: sumBy(inSelectedList, _item => get(_item[i], [_keys[0]])),
            female: sumBy(inSelectedList, _item => get(_item[i], [_keys[1]]))
        })
    }
    return result || [];
}

export const calculateDashboardChartDataPercent = (data = [], selected = []) => {
    let selectedList = filter(data, (_item) => includes(selected, get(_item, 'id'))) || [];
    selectedList = selectedList.map(item => isObject(get(item, 'age_groups')) ? values(get(item, 'age_groups')) : get(item, 'age_groups')) || [];
    let keys = isArray(find(selectedList, (_list) => !isEmpty((_list)))) && find(selectedList, (_list) => !isEmpty((_list))).map(_item => _item.code);

    const totalPopulation = sumBy(selectedList, ageGroupList =>
      sumBy(ageGroupList, ageGroup => get(ageGroup, 'male', 0) + get(ageGroup, 'female', 0))
    );

    let result = [];
    for (let i = 0; i < keys.length; i++) {
        const ageGroupTotal = sumBy(selectedList, _item => get(_item[i], 'male', 0) + get(_item[i], 'female', 0));

        const ageGroupPercentage = totalPopulation ? ((ageGroupTotal / totalPopulation) * 100).toFixed(1) : 0;

        result.push({
            code: keys[i],
            total: ageGroupPercentage
        });
    }
    return result || [];
};
